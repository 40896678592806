<template>
  <div>
    <div class="staff">
      <div class="left-top">
        <span style="font-size: 18px; font-weight: 400; color: #333"
          >友情链接配置</span
        >
        <el-divider></el-divider>
        <el-button
          type="primary"
          @click="addPage"
          style="float: left; margin: 10px 5px; padding: 15px 20px"
          ><i class="el-icon-circle-plus-outline" style="margin-right: 5px"></i
          >添加链接</el-button
        >
      </div>
      <el-table
        border
        :data="PageConfigure"
        row-key="CategoryGuid"
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          fixed
          sortable
          label="序号"
          width="100"
          prop="Sort"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="链接名称"
          width="150"
          prop="LinkName"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="站长联系人"
          width="150"
          prop="StationContactor"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="NoFollow"
          width="100"
          prop="Followshow"
          align="center"
        >
          <template slot-scope="scope">
            <span style="font-weight: 700">{{ scope.row.Followshow }}</span>
          </template>
        </el-table-column>
        <el-table-column label="链接地址" prop="LinkUrl" align="center">
        </el-table-column>
        <el-table-column
          label="是否开启"
          width="120"
          prop="IsEnable"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch
              @change="changeswitch(scope.row)"
              v-model="scope.row.IsEnable"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <span class="switchvalue" v-show="scope.row.IsEnable">开启</span>
            <span class="switchvalue close" v-show="!scope.row.IsEnable"
              >关闭</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间"
          width="180"
          prop="EditTime"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作" align="center">
          <template slot-scope="scope">
            <span
              class="backSpan"
              @click="eduit(scope.row)"
              style="color: #409eff"
            >
              编辑
            </span>
            <span
              v-show="!scope.row.IsEnable"
              class="backSpan"
              @click="PreDelet(scope.row)"
              style="color: #cc0000"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>

      <!-- 编辑 -->
      <div class="formdia">
        <el-dialog
          :modal-append-to-body="false"
          :visible.sync="dialogFormVisible"
          custom-class="edit-form"
          :title="dialongtitle"
        >
          <el-form :model="Rowcontent" :rules="rules" ref="editForm">
            <el-form-item
              label="链接名称:"
              :label-width="formLabelWidth"
              prop="LinkName"
            >
               <el-input
                style="width: 340px"
                v-model="Rowcontent.LinkName"
                placeholder="请输入链接名称"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="链接地址:"
              :label-width="formLabelWidth"
              prop="LinkUrl"
            >
              <el-select
                style="width: 80px"
                v-model="value"
                @change="httptpye"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in twoAlltype"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                style="width: 260px"
                v-model="Rowcontent.LinkUrl"
                placeholder="请输入链接地址"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="站长联系人:"
              :label-width="formLabelWidth"
              prop="StationContactor"
            >
              <el-input
                style="width: 340px"
                v-model="Rowcontent.StationContactor"
                placeholder="请输入站长联系人"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="NoFollow:"
              type="number"
              :label-width="formLabelWidth"
            >
              <el-radio v-model="Rowcontent.radio" label="0" @change="radio0"
                >不显示</el-radio
              >
              <el-radio v-model="Rowcontent.radio" label="1" @change="radio1"
                >显示</el-radio
              >
            </el-form-item>
            <el-form-item
              label="排列序号:"
              type="number"
              :label-width="formLabelWidth"
              prop="Sort"
            >
              <el-input
                style="width: 340px"
                v-model="Rowcontent.Sort"
                placeholder="请输入文章序号"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="是否开启:"
              type="number"
              :label-width="formLabelWidth"
              prop="editEnable"
            >
              <el-switch
                @change="switchenable"
                v-model="Rowcontent.editEnable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
              <span
                style="color: #13ce66; padding-left: 8px"
                v-show="Rowcontent.editEnable"
                >开启</span
              >
              <span
                style="color: #ff4949; padding-left: 8px"
                v-show="!Rowcontent.editEnable"
                >关闭</span
              >
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">返回</el-button>
            <el-button :disabled="isDisabled" @click="submitForm('editForm')">
              确定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";

export default {
  data() {
    var numberRules = (rule, value, callback) => {
      if (/^[0-9]\d*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    };
    return {
      //验证
      rules: {
        LinkName: [{ required: true, message: "请完善信息", trigger: "blur" }],
        Sort: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: numberRules, trigger: "blur" },
        ],
        StationContactor: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        LinkUrl: [{ required: true, message: "请完善信息", trigger: "blur" }],
      },

      dialogFormVisible: false,
      dialongtitle: "",
      formLabelWidth: "100px",
      Rowcontent: {
        Id: 0,
        LinkName: "",
        LinkUrl: "",
        IsEnable: true,
        Sort: 0,
        StationContactor: "",
        HttpType: "",
        NoFollow: true,
        radio: "0",
        editEnable: true,
      },

      PageConfigure: [], //列表分页数据
      Params: {
        pageIndex: 1,
        pageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      IsEdit: false,
      twoAlltype: [
        { label: "http", value: "http" },
        { label: "https", value: "https" },
      ], //获取所有分类
      value: "http",
      imgshow: "1",
      //解决重复点击问题
      isDisabled: false,
    };
  },
  mounted() {
    this.searchDate();
  },
  methods: {
    //获取数据
    searchDate() {
      var _this = this;
      this.$get(_this.$api.GetLinkList, _this.Params)
        .then((res) => {
          console.log(res.PageIndex);
          _this.PageConfigure = res.Data;
          console.log(this.PageConfigure);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
          _this.PageConfigure.forEach((item, index) => {
            item.NoFollow == true
              ? (item.Followshow = "是")
              : (item.Followshow = "否");
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //是否开启
    changeswitch(row, index, data) {
      let enable;
      row.IsEnable == true ? (enable = 1) : (enable = 0);
      this.$post(
        this.$api.EnableLink + "?id=" + row.Id + "&isEnable=" + enable
      ).then((res) => {
        if (res.IsSuccess) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: "800",
          });
          // this.searchDate();
        }
      });
    },
    switchenable() {
      this.Rowcontent.IsEnable = this.Rowcontent.editEnable;
      console.log(this.Rowcontent.IsEnable);
    },
    //显示
    radio0() {
      this.Rowcontent.NoFollow = false;
    },
    radio1() {
      this.Rowcontent.NoFollow = true;
    },

    //http类型
    httptpye() {
      this.Rowcontent.HttpType = this.value;
      console.log(this.Rowcontent.HttpType);
    },
    //新增
    addPage() {
      this.IsEdit = false;
      this.dialongtitle = "新增友情链接";
      this.dialogFormVisible = true;
      this.Rowcontent.Id = 0;
      this.Rowcontent.LinkName = "";
      this.Rowcontent.LinkUrl = "";
      this.Rowcontent.StationContactor = "";
      this.Rowcontent.Sort = "";
      this.Rowcontent.IsEnable = false;
      this.Rowcontent.editEnable = false;
      this.value = "http";
      this.Rowcontent.HttpType = this.value;
      this.Rowcontent.NoFollow = false;
    },

    //编辑
    eduit(val) {
      var _this = this;
      this.IsEdit = true;
      this.dialongtitle = "编辑文章类型";
      this.dialogFormVisible = true;
      console.log(val);
      this.Rowcontent.Id = val.Id;
      this.Rowcontent.LinkName = val.LinkName;
      this.Rowcontent.LinkUrl = val.LinkUrl;
      this.Rowcontent.StationContactor = val.StationContactor;
      this.Rowcontent.Sort = val.Sort;
      this.Rowcontent.editEnable = val.IsEnable;
      this.Rowcontent.IsEnable = val.IsEnable;
      this.value = val.HttpType;
      // this.Rowcontent.HttpType = this.value;
      this.Rowcontent.NoFollow = this.value;
      val.NoFollow == true
        ? (this.Rowcontent.radio = "1")
        : (this.Rowcontent.radio = "0");
      this.Rowcontent.NoFollow = val.NoFollow;
    },
    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },

    submitForm(editForm) {
      var _this = this;
      this.isDisabled = true;
      this.entime();
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          if (this.IsEdit) {
            console.log("编辑");
            console.log(this.Rowcontent);
            this.$post(this.$api.UpdateLink, this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          } else {
            console.log("新增");
            console.log(this.Rowcontent);
            this.$post(this.$api.AddLink, this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.pageIndex = val;
      console.log(this.Params.PageIndex);
      this.searchDate();
    },
    handleSizeChange(val) {
      this.Params.pageSize = val;
      console.log(val);
      this.searchDate();
    },

    //删除
    PreDelet(row, index, data) {
      this.$confirm("确认删除？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
      })
        .then(() => {
          this.$post(this.$api.DeleteLink + "?id=" + row.Id)
            .then((res) => {
              if (res.IsSuccess) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .backSpan {
    padding: 0px 20px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

.switchvalue {
  font-size: 13px;
  color: #13ce66;
  padding-left: 5px;
  -moz-user-select: none; /* 火狐 */
  -webkit-user-select: none; /* webkit浏览器 */
  -ms-user-select: none; /* IE10 */
  -khtml-user-select: none; /* 早期浏览器 */
  -o-user-select: none; /* Opera */
  user-select: none;
}

.switchvalue.close {
  color: #ff4949;
}

/* 编辑框样式 */
/deep/.edit-form {
  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      line-height: 0px;
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }

  img.upImg {
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    float: left;
  }
}

// 小星星
.xiaoxx {
  color: #F56C6C;
  position: absolute;
  top: 0;
  left: -10px;
}
</style>